import React from 'react';
import { getArticleOrCategorySlugs } from '../../../utilities';
import ArticleCardBlue from '../../Common/Article/ArticleCardBlue';
import { Image } from '../../Common/Image';

const NotOverrideArticleSidebar = ({ relatedArticles, infographicArticle }) => {
     if (infographicArticle) {
          var relatedWhitePaper = infographicArticle.relatedWhitePaper ? infographicArticle.relatedWhitePaper :
               (infographicArticle.category.relatedWhitePaper ? infographicArticle.category.relatedWhitePaper : null);
     }

     if (infographicArticle) {
          var infographicArticleSlug = `/healthzone/${getArticleOrCategorySlugs(infographicArticle.category).reverse().join('/')}/${infographicArticle.slug}/`
     }

     return (
          <>
               {
                    (infographicArticle && relatedWhitePaper) && (
                         <a href={infographicArticleSlug} className="infographic">
                              <Image image={relatedWhitePaper} altOverride={(infographicArticle.contentName) ? infographicArticle.contentName : ''} />
                         </a>
                    )
               }
               {
                    relatedArticles.length > 0 && (
                         <>
                              {/* <h3>Related Articles</h3> */}
                              {
                                   relatedArticles.map(({ node: relatedArticle }, index) => {
                                        let slugs = `/healthzone/${getArticleOrCategorySlugs(relatedArticle.category).reverse().join('/')}/${relatedArticle.slug}/`;

                                        return (
                                             <div key={index} className="article-detail article-block">
                                                  {/* <a href={slugs} className="outline-none" tabIndex={-1}>
                                                       <div className="image-wrap">
                                                            {relatedArticle.featuredImage && <Image image={relatedArticle.featuredImage} altOverride={(relatedArticle.contentName) ? relatedArticle.contentName : ''} />}
                                                       </div>
                                                  </a>
                                                  <div className="content">
                                                       <a href={slugs}>
                                                            <h5>{relatedArticle.contentName}</h5>
                                                       </a>
                                                       {relatedArticle.shortDescription && <p>{relatedArticle.shortDescription.substr(0, 90)}...</p>}
                                                  </div> */}
                                                        <ArticleCardBlue article={relatedArticle} slugs={slugs} />
                                             </div>
                                        )
                                   })
                              }
                         </>
                    )
               }
          </>
     )
}

export default NotOverrideArticleSidebar;
