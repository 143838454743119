import React from 'react';
import { getArticleOrCategorySlugs } from '../../../utilities';
import ArticleCardBlue from '../../Common/Article/ArticleCardBlue';
import { Image } from '../../Common/Image';

const OverrideArticleSidebar = ({ sidebarArticles }) => {
     const infographicArticles = sidebarArticles.filter(article => {
          return article.isInfographic;
     })

     const relatedArticles = sidebarArticles.filter(article => {
          return !article.isInfographic;
     })

     return (
          <>
               {
                    infographicArticles.length > 0 && infographicArticles.map(((infographicArticle, i) => {
                         const relatedWhitePaper = infographicArticle.relatedWhitePaper ? infographicArticle.relatedWhitePaper :
                              (infographicArticle.category.relatedWhitePaper ? infographicArticle.category.relatedWhitePaper : null);
                         let slugs = `/healthzone/${getArticleOrCategorySlugs(infographicArticle.category).reverse().join('/')}/${infographicArticle.slug}/`;

                         if (relatedWhitePaper) {
                              return (
                                   <a href={slugs} className="infographic" key={i}>
                                        <Image image={relatedWhitePaper} altOverride={(infographicArticle.contentName) ? infographicArticle.contentName : ''} />
                                   </a>
                              )
                         } else {
                              return (
                                   <React.Fragment key={i}>
                                   </React.Fragment>
                              )
                         }
                    }))
               }
               {
                    relatedArticles.length > 0 && (
                         <>
                              {/* <h3>Related Articles</h3> */}
                              {
                                   relatedArticles.map((relatedArticle, index) => {
                                        let slugs = `/healthzone/${getArticleOrCategorySlugs(relatedArticle.category).reverse().join('/')}/${relatedArticle.slug}/`;

                                        return (
                                             <div key={index} className="article-detail article-block">
                                                  {/* <a href={slugs} className="outline-none" tabIndex={-1}>
                                                       <div className="image-wrap">
                                                            {relatedArticle.featuredImage && <Image image={relatedArticle.featuredImage} altOverride={(relatedArticle.contentName) ? relatedArticle.contentName : ''} />}
                                                       </div>
                                                  </a>
                                                  <div className="content">
                                                       <a href={slugs}>
                                                            <h5>{relatedArticle.contentName}</h5>
                                                       </a>
                                                       {relatedArticle.shortDescription && <p>{relatedArticle.shortDescription.substr(0, 90)}...</p>}
                                                  </div> */}
                                                        <ArticleCardBlue article={relatedArticle} slugs={slugs} />
                                             </div>
                                        )
                                   })
                              }
                         </>
                    )
               }
          </>
     )
}

export default OverrideArticleSidebar;
