import React, { Component } from 'react';
import Slider from 'react-slick';
import { RichText } from '../../../components/richTextOptions';
import { Image } from '../Common/Image';
import ShareSocials from '../Common/ShareSocials';
import NotOverrideArticleSidebar from './ArticleSidebar/NotOverrideArticleSidebar';
import OverrideArticleSidebar from './ArticleSidebar/OverrideArticleSidebar';

class ArticleDetailBody extends Component {
     constructor(props) {
          super(props);

          this.state = {
               firstName: '',
               emailAddress: '',
               slideIndex: 0,
          }
     }

     onFirstNameChanged = (e) => this.setState({ firstName: e.target.value })
     onEmailAddressChanged = (e) => this.setState({ emailAddress: e.target.value })

     // handleSubmit = () => {
     //      if (this.state.firstName && this.state.emailAddress) {
     //           let body = {
     //                emailId: this.state.emailAddress
     //           }

     //           fetch('https://phz8.petinsurance.com/api/sitecore/NewsLetterSubscription/Subscribe', {
     //                method: 'POST',
     //                body: JSON.stringify(body)
     //           }).then(response => {
     //                let json = response.json();
     //           })
     //                .catch(error => {
     //                     console.error(
     //                          "There has been a problem with your fetch operation:",
     //                          error
     //                     );
     //                });
     //      }
     // }

     render() {
          const { article, relatedArticles, infographicArticle } = this.props;
          const { slideIndex } = this.state;
          const settings = {
               autoplay: true,
               autoplaySpeed: 5000,
               speed: 600,
               dots: false,
               arrows: true,
               infinite: true,
               slidesToShow: 1,
               slidesToScroll: 1,
               lazyLoad: true,
               // waitForAnimate: false,
               beforeChange: (current, next) => this.setState({ slideIndex: next }),
          };
          const featuredImage = article.featuredImage?.file.url;
          if (article.photos) {
               var currentImageContent = article.photos[slideIndex];
          }
          
          return (
               <>
                    <section className={`article-content`}>
                         <div className="container">
                              <div className="row">
                                   <div className="col-lg-8">
                                        {article.contentName && <div className="headline-layout"><h1>{article.contentName}</h1></div>}
                                        {
                                             article.photos ? (
                                                  <>
                                                       <div className="body-content-wrap">
                                                            {article.body && <RichText data={article.body}></RichText>}
                                                       </div>
                                                       <div className="article-slider">
                                                            <Slider ref={slider => (this.sliderArticle = slider)} {...settings}>
                                                                 {
                                                                      article.photos.map((photo, i) => {
                                                                           return (<div key={i} className="slide-item">
                                                                                <img loading="lazy"
                                                                                     src={photo.file.url}
                                                                                     alt={photo.title}
                                                                                />
                                                                           </div>)
                                                                      })
                                                                 }
                                                            </Slider>
                                                       </div>
                                                       <ul className="slider-thumbs horizontal-scroll">
                                                            {
                                                                 article.photos.map((photo, i) => {
                                                                      return (
                                                                           <li key={i} className={`slider-navigation ${(slideIndex == i) ? 'active' : ''}`} >
                                                                                <img loading="lazy"
                                                                                     src={photo.file.url}
                                                                                     alt={photo.title}
                                                                                     className="img-slider-responsive"
                                                                                     onMouseEnter={() => this.sliderArticle.slickGoTo(i)}
                                                                                     onClick={() => this.sliderArticle.slickGoTo(i)}
                                                                                />
                                                                           </li>
                                                                      )
                                                                 })
                                                            }
                                                       </ul>
                                                       <div className="slider-description">
                                                            <div className="description">
                                                                 <h2>{slideIndex + 1}. {currentImageContent.title}</h2>
                                                                 <p>{currentImageContent.description}</p>
                                                                 <p className="carousel-index">{slideIndex + 1} of {article.photos.length}</p>
                                                            </div>
                                                       </div>
                                                  </>
                                             ) : (
                                                       <>
                                                            {
                                                                 !article.isInfographic && (
                                                                      <>
                                                                           {
                                                                                (article.slug === 'about-one-cure') ? (
                                                                                     <div className="article-content-one-cure">
                                                                                          <Image image={article.featuredImage} altOverride={(article.contentName) ? article.contentName : ''} />
                                                                                     </div>
                                                                                ) : (
                                                                                     <div className={`${article.featuredImage ? 'healthzone-intro-image' : ''}`}>
                                                                                          <Image image={article.featuredImage} altOverride={(article.contentName) ? article.contentName : ''} />
                                                                                     </div>
                                                                                )
                                                                           }
                                                                      </>
                                                                 )
                                                            }
                                                            <div className="body-content-wrap">
                                                                 {article.body && <RichText data={article.body}></RichText>}
                                                            </div>
                                                       </>
                                                  )


                                        }

                                        {/* <div className="share-article">
                                             <p>Share this article on</p>
                                             <div className="social">
                                                  <ul>
                                                       <li>
                                                            <a target="_blank" href="https://www.facebook.com/NationwidePet/">
                                                            <img loading="lazy" src="/images/icons/Messenger.svg" alt="Messenger" />
                                                            </a>
                                                       </li>
                                                       <li>
                                                            <a target="_blank" href="https://www.facebook.com/NationwidePet/">
                                                            <img loading="lazy" src="/images/icons/Facebook.svg" alt="Messenger" />
                                                            </a>
                                                       </li>
                                                      
                                                       <li>
                                                            <a target="_blank" href="https://www.instagram.com/nationwidepet/">
                                                            <img loading="lazy" src="/images/icons/LinkedIN.svg" alt="LinkedIN" />
                                                            </a>
                                                       </li>
                                                       <li>
                                                            <a target="_blank" href="https://www.instagram.com/nationwidepet/">
                                                            <img loading="lazy" src="/images/icons/Pinterest.svg" alt="Pinterest" />
                                                            </a>
                                                       </li>
                                                       <li>
                                                            <a target="_blank" href="https://twitter.com/NationwidePet">
                                                            <img loading="lazy" src="/images/icons/Twitter.svg" alt="Twitter" />
                                                            </a>
                                                       </li>
                                                  </ul>
                                             </div>
                                        </div> */}
                                        <ShareSocials titleShare="Share this article on" imageArticleUrl={featuredImage} />
                                   </div>
                                   <div className="col-lg-4 healthzone-subcribe-form">
                                        <div className="article-sidebar">
                                             {/* <div className="share-article">
                                                  <p>Share this article on</p>
                                                  <div className="social">
                                                       <ul>
                                                            <li>
                                                                 <a target="_blank" href="https://www.facebook.com/NationwidePet/">
                                                                      <img loading="lazy" src="/images/healthzone-social-icon/facebook.png" alt='facebook' />
                                                                 </a>
                                                            </li>
                                                            <li>
                                                                 <a target="_blank" href="https://twitter.com/NationwidePet">
                                                                      <img loading="lazy" src="/images/healthzone-social-icon/twitter.png" alt='twitter' />
                                                                 </a>
                                                            </li>
                                                            <li>
                                                                 <a target="_blank" href="https://www.instagram.com/nationwidepet/">
                                                                      <img loading="lazy" src="/images/healthzone-social-icon/instagram.png" alt='instagram' />
                                                                 </a>
                                                            </li>
                                                       </ul>
                                                  </div>
                                             </div> */}
                                             {/* <form>
                                                  <h4>Get Informative Articles Delivered Weekly</h4>
                                                  <div className="form-group">
                                                       <label htmlFor="firstName">First name</label>
                                                       <input
                                                            id="firstName"
                                                            type="text"
                                                            className="form-control"
                                                            id="firstName"
                                                            value={this.state.firstName}
                                                            onChange={this.onFirstNameChanged}
                                                       />
                                                  </div>
                                                  <div className="form-group">
                                                       <label htmlFor="emailAddress">Enter email address</label>
                                                       <input
                                                            id="emailAddress"
                                                            type="email"
                                                            className="form-control"
                                                            id="emailAddress"
                                                            value={this.state.emailAddress}
                                                            onChange={this.onEmailAddressChanged}
                                                       />
                                                  </div>
                                                  <p>By subscribing, you agree to our Terms of Use and Privacy Policy.</p>
                                                  <button type="submit" className="subcribe-btn">Subscribe</button>
                                             </form> */}
                                             {
                                                  article.sidebarArticles ? (
                                                       <OverrideArticleSidebar sidebarArticles={article.sidebarArticles} />
                                                  ) : (
                                                            <NotOverrideArticleSidebar
                                                                 relatedArticles={relatedArticles}
                                                                 infographicArticle={infographicArticle}
                                                            />
                                                       )
                                             }
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </section>
               </>
          )
     }
}

export default ArticleDetailBody;
